.footer {
  padding: 50px 0 32px;
  background-color: #255CD8;
  .logo {
    margin-bottom: 45px;
    transition: all 0.8s ease;
    img {
      width: 90px;
    }
  }
  .address {
    max-width: 308px;
    font-size: 25px;
    line-height: 110%;
    color: #fff;
    font-weight: 500;
    margin-bottom: 63px;
    transition: all 0.8s ease;
  }
  .links {
    display: flex;
    flex-direction: column;
    a {
      font-size: 25px;
      text-decoration: none;
      color: #fff;
      font-weight: 500;
      margin-bottom: 28px;
      position: relative;
      width: fit-content;
      transition: all 0.8s ease;
      line-height: 1.1;
      &::before {
        content: " ";
        position: absolute;
        bottom: 4px;
        left: 0;
        width: 0px;
        height: 1px;
        background-color: #fff;
        transition: all 0.4s ease;
      }
      &:hover {
        color: #fff;
        &::before {
          width: 100%;
        }
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .copys {
    a,
    p {
      font-size: 17px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.7);
      transition: all 0.8s ease;
    }
    a {
      margin-right: 55px;
      text-decoration: none;
      position: relative;
      width: fit-content;
      &::before {
        content: " ";
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 0px;
        height: 1px;
        background-color: #fff;
        transition: all 0.4s ease;
      }
      &:hover {
        color: #fff;
        &::before {
          width: 100%;
        }
      }
    }
    p {
      display: inline-block;
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .footer {
    padding: 15px 0 !important;
    .logo {
      margin-bottom: 25px !important;
      img {
        width: 80px !important;
      }
    }
    .address {
      font-size: 18px !important;
      line-height: 1.1;
      margin-bottom: 30px !important;
    }
    .links {
      margin-bottom: 35px !important;
      a {
        font-size: 18px !important;
        margin-bottom: 15px !important;
        &:last-child {
          margin-bottom: 0px !important;
        }
      }
    }
    .copys {
      a,
      p {
        font-size: 14px !important;
      }
      a {
        margin-right: 35px !important;
        &::before {
          display: none;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1180px) {
  .footer {
    padding: 30px 0 !important;
    .logo {
      margin-bottom: 42px;
      img {
        width: 90px !important;
      }
    }
    .address {
      font-size: 22px !important;
      margin-bottom: 85px !important;
      line-height: 1.1;
    }
    .links {
      a {
        font-size: 22px !important;
        margin-bottom: 24px;
      }
    }
    .copys {
      a,
      p {
        font-size: 14px !important;
      }
      a {
        margin-right: 50px !important;
      }
    }
  }
}

@media screen and (min-width: 1380px) {
  .logo {
    a {
      text-decoration: none;
      img {
        transition: all 0.8s ease;
        &:hover {
          transform: scale(0.9);
        }
      }
    }
  }
}

@media screen and (min-width: 1920px) {
  .footer {
    .logo {
      margin-bottom: 55px !important;
      img {
        width: 108px !important;
      }
    }
    .address {
      font-size: 28px !important;
      max-width: 371px !important;
      margin-bottom: 95px !important;
      line-height: 110%;
    }
    .copys {
      a,
      p {
        font-size: 22px !important;
      }
      a {
        margin-right: 58px !important;
      }
    }
    .links {
      a {
        font-size: 28px !important;
        margin-bottom: 33px !important;
      }
    }
  }
}