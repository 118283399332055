.xcard {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, .12);
  padding: 10px 12px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, .15);
  }
  .xcard_image {
    min-width: 64px;
    width: 64px;
    height: 64px;
    border-radius: 8px;
    margin-right: 16px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .xcard_data {
    width: 100%;
    .xcard_data__id {}
    .xcard_data__title {
      font-size: 18px;
      line-height: 19px;
      font-weight: 600;
    }
    .xcard_data__st {
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      span {
        margin-left: 10px;
        opacity: 0.4;
        // font-size: 12px;
        display: none;
      }
    }
  }
  .xcard_actions {
    opacity: 0.2;
  }
}